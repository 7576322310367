
:root {
  --navy: #003D59;
	/* #003D59; */
  --red: #CD2F05;
  --orange: #FB9334;
  --green: green;
  --light-gray: #EEF2F4;
	--teal: #177070;
  --dark-blue: #232d65;
	--primary-blue: #3789B1;
	--light-blue: #2fbbd9; /* #20a8d8; */
	--gray: #727a82;
	--color-text-primary: #24292e;
	--active-blue: #20a8d8;
}

body {
	font-size: 12px;
	color: var(--color-text-primary);
	font-family: Roboto, sans-serif;
	background-color: #f3f5f7;
}

.app-body .main {
	overflow-x: scroll;
}

.main-container {
	padding: 0px !important;
	min-width: 840px;
}

.btn-clear.btn-secondary,
.btn-clear.btn-secondary:active:focus, 
.btn-clear.btn-secondary:not(:disabled):not(.disabled):active:focus, 
.btn-clear.btn-secondary:not(:disabled):not(.disabled):focus, 
.btn-clear.btn-secondary.dropdown-toggle {
	background-color: transparent;
	padding: 0px;
	border: none;
	box-shadow: none;
	color: var(--gray);
}

.btn-clear.btn-secondary:hover {
	color: var(--navy);
	background-color: transparent;
}

.btn-clear-white .btn-secondary,
.btn-clear-white .btn-secondary:active:focus, 
.btn-clear-white .btn-secondary:not(:disabled):not(.disabled):active:focus, 
.btn-clear-white .btn-secondary:not(:disabled):not(.disabled):focus, 
.btn-clear-white .btn-secondary.dropdown-toggle {
	background-color: transparent;
	padding: 0px;
	border: none;
	color: white;
	box-shadow: none;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
	box-shadow: none;
}

.login-btn {
	background-color: var(--primary-blue) !important;
	border-color: var(--primary-blue) !important;
}

.login-btn:hover {
	background-color: var(--navy) !important;
	color: white !important;
}

h1, .h1 {
	font-size: 32px;
}

h2, .h2 {
	font-size: 24px;
}

h3, .h3 {
	font-size: 16px;
	font-weight: 500;
}

p {
	font-size: 14px;
	font-family: Roboto, sans-serif;
	margin-bottom: 0;
}

h4, .h4 {
	text-transform: uppercase;
	margin: 0;
	margin-right: .6rem;
	font-size: 16px;
	color: var(--teal) !important;
	letter-spacing: .5px;
	font-weight: 500;
	margin-bottom: .4rem;
}

h5, .h5 {
	font-size: 14px;
	font-weight: 500;
}

h6, .h6 {
	text-transform: uppercase;
	margin: 0;
	font-size: 14px;
	color: var(--gray);
	letter-spacing: .5px;
	font-weight: 500;
}

h1, h2, h3, h4, h5, h6 {
	line-height: 1.5;
	margin-bottom: 0;
}

.styled-radio svg{
	font-size: 18px;
	color: var(--navy);
}

.styled-check-danger svg{
	color: var(--red);
	font-size: 22px;
}

.styled-check-warning svg{
	color: var(--orange);
	font-size: 22px;
}

.styled-check-success svg{
	color: var(--green);
	font-size: 22px;
}

.styled-stepper svg{
	color: var(--navy);
}

.styled-circular-progress svg {
	color: var(--navy);
}

.shadow-card {
	border: none;
	box-shadow: 0 0 0 1px rgba(89,105,129,.1), 0 1px 3px 0 rgba(89,105,129,.1), 0 1px 2px 0 rgba(0,0,0,.05);
}

.card-header-light {
	background-color: transparent;
}

.btn-link {
	color: var(--gray);
	font-size: 14px;
}

a {
	color: var(--gray);
}

.table td {
	border: none
}

.table .thead-light th {
	border: none;
	border-bottom: 1px solid var(--light-gray);
	background-color: transparent;
}

.table-hover tbody tr:hover {
	background-color: var(--light-gray);
}

.custom-list-group {
	border: none;
}

.custom-list-group:hover {
	background-color: var(--light-gray);
}

.custom-list-group:first-child {
	border-top-right-radius: 0px;
	border-top-left-radius: 0px;
}

.bg-light-gray {
	background-color: var(--light-gray);
}

.bg-navy {
	background-color: var(--navy) !important;
}

.bg-dark-blue {
	background-color: var(--dark-blue) !important;
}

.bg-blue {
	background-color: var(--primary-blue) !important;
}

.bg-light-blue {
	background-color: var(--light-blue) !important;
}

.color-light-blue {
	color: var(--light-blue) !important;
}

.color-navy {
	color: var(--navy);
}

.color-red {
	color: var(--red);
}

.nav-link.active {
	color: var(--light-blue) !important;
}

.modal-content {
	border: none;
}

.btn {
	padding: .25rem 1rem;
	font-size: 14px;
	background-color: var(--navy);
	border-color: var(--navy);
	color: white;
}

.btn:hover {
	background-color: #1A75A1;
	border-color: #1A75A1;
	color: white;
}

.btn-light {
	background-color: var(--light-gray);
	border-color: var(--light-gray);
	color: var(--navy);
}

.btn-light:hover {
	background-color: #CBD8DF;
	border-color: #CBD8DF;
	color: var(--navy);
}

.btn-outline-secondary {
	border-color: var(--navy);
	background-color: transparent;
	color: var(--navy);
}

.btn-outline-secondary:hover {
	border-color: var(--navy);
	background-color: var(--navy);
	color: white;
}

.show > .btn-outline-secondary.dropdown-toggle {
	border-color: var(--navy);
	background-color: var(--navy);
	color: white;
}

.btn-ghost-secondary {
	background-color: transparent;
	border: none;
	color: var(--navy);
}

.btn-ghost-secondary:hover {
	border-color: var(--navy);
	background-color: var(--navy);
	color: white;
}

.btn-outline-danger {
	color: var(--red);
	border-color: var(--red);
	background-color: transparent;
}

.btn-outline-danger:hover {
	color: white;
	background-color: var(--red);
	border-color: var(--red);
}

.btn-link {
	text-decoration: underline;
	color: var(--gray);
	background-color: transparent;
	border: none;
}

.btn-link:hover {
	color: var(--navy);
	background-color: transparent;
}

.btn-danger {
	background-color: var(--red);
	border-color: var(--red);
}

.btn-danger:hover {
	background-color: #902b2b;
	border-color: var(--red);
}

.sidebar .nav-element {
	color: white;
	margin-left: 4rem;
	font-size: 12px;
	font-weight: 400;
	display: block;
	padding: .5rem;
	cursor: pointer;
}

.sidebar .nav-element:hover {
	color: var(--teal);
}

.container-fluid {
	max-width: 1400px;
}

.warning-bg {
	background-color: var(--orange) !important;
}

.success-bg {
	background-color: var(--green) !important;
}

.danger-bg {
	background-color: var(--red) !important;
}

.small-chart {
	max-width: 300px;
	margin: auto;
}

.xs-chart {
	max-width: 200px;
	margin: auto;
}

.tree-node {
	padding: 10px;
	width: 100%;
	background-color: transparent;
	border-radius: none;
}

.tree-card {
	border: none;
	border-radius: none;
} 

.transparent {
	background-color: transparent;
	border: none;
	box-shadow: none;
}

.score-table {
	text-align: center;
}

.over-under {
	border-bottom: 1px solid;
  border-top: 1px solid;
}

.right-vertical-divider {
	border-right: 1px solid #c8ced3;
}

.nav-link-highlight {
	background-color: var(--gray) !important;
}

.manager-navbar {
	height: 42px;
    position: fixed;
    width: 100%;
    z-index: 1000;
}

.progress-bar {
	background-color: var(--navy);
}

.absolute-center {
  position:absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn-blue {
	background-color: transparent;
	border: 1px solid var(--navy);
	box-shadow: none;
	color: var(--navy);
}

.btn-blue:active
.btn-secondary:not(:disabled):not(.disabled):active, 
.btn-secondary:not(:disabled):not(.disabled).active {
	background-color: var(--active-blue);
	border: none;
	box-shadow: none;
	color: white;
}